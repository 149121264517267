// import service from '@/middleware'
import http from "@/middleware";
const generalConfigurationApi = {
  /**
   * Obtener todas las cosas del tipo enviado como parámetro
   * @param key
   * @param id
   */
  async getConfig(key, id) {
    let result = await http.post('/api/general-configuration/get-config-key-id', { key, id })
    return result
  },

  async getConfigSystemVersion(id) {
    let result = await http.post('/api/general-configuration/get-config-system-version', { id })
    return result
  },

  /**
   * Obtener todas las configuraciones de la key enviada como parámetro
   * @param key
   */
  async getByKey(key) {
    const result = await http.get(`/api/general-configuration/by-key/${key}`)
    return result
  },
  /**
   * Actualizar o crear las configuraciones de la key enviada como parámetro
   * @param key
   * @param data
   */
  async setByKey(key, data) {
    return await http.put(`/api/general-configuration/by-key/${key}`, {data})    
  },
}

export default generalConfigurationApi
