import authApi from "@/api/auth.api";
// import userApi from "@/api/user.api";
import enterpriseApi from "@/api/enterprise.api";
import generalConfigurationApi from "@/api/general-configuration.api"
import { loginService } from "@/business/loginService";
import { messagingService } from "@/business/messagingService";
import {
  ColvenTheme,
  ResetPasswordType,
  SnackbarStyle
} from "@/constants/constants";
import i18n from "@/i18n";
import i18nOriginal from "@/i18nOriginal";
import { authService } from "@/business/auth.service";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "LoginComponent",
  data() {
    return {
      validForm: false,
      showPassword: false,
      username: "",
      password: "",
      remember: false,
      submitted: false,
      rules: {
        required: value => !!value || `${i18n.t("required")}`
      },
      languages: [
        {
          id: "es",
          locale: "es",
          name: i18n.t("login.spanish")
        },
        {
          id: "us",
          locale: "en",
          name: i18n.t("login.english")
        },
        {
          id: "br",
          locale: "pt",
          name: i18n.t("login.brazilian")
        }
      ],
      selectedLanguage: {
        id: "es",
        locale: "es",
        name: i18n.t("login.spanish")
      },
      showSelectEnterprise: false,
      enterprises: [],
      selectedEnterprise: null,
      systemVersion: null,
      loadVersion: false
    };
  },
  async mounted() {
    this.clearSessionData();
    this.setNotifications([]);

    i18n.mergeLocaleMessage("es", JSON.parse(i18nOriginal.original).es);
    if (localStorage.locale) {
      this.setLocale(localStorage.locale);
      switch (localStorage.locale) {
        case "es":
          this.selectedLanguage = this.languages[0];
          break;
        case "en":
          this.selectedLanguage = this.languages[1];
          break;
        case "pt":
          this.selectedLanguage = this.languages[2];
          break;
        default:
          this.selectedLanguage = this.languages[0];
          break;
      }
    } else {
      // const navigatorLanguage = navigator.language
      this.setLanguage(this.languages[0]);
    }
    if (localStorage.remember) {
      const rememberLocalStorage = localStorage.getItem("remember") === "true";
      this.setRemember(rememberLocalStorage);
      this.remember = this.getRemember;
    }
    if (this.getRemember === true && localStorage.getItem("username")) {
      this.setUsername(localStorage.username);
      this.username = this.getUsername;
    }
    if (!this.loadVersion) {
      const config = await generalConfigurationApi.getConfigSystemVersion('products')
      if (config && config.data && config.data.version) {
        localStorage.setItem('systemVersion', config.data.version)
        this.systemVersion = config.data.version
      }
      this.loadVersion = true
    }
  },
  beforeDestroy() {
    // Carga los temas
    if (this.getThemes) {
      this.$vuetify.theme.themes.light = this.getThemes.light;
      this.$vuetify.theme.themes.dark = this.getThemes.dark;
    } else {
      this.$vuetify.theme.themes.light = ColvenTheme.light;
      this.$vuetify.theme.themes.dark = ColvenTheme.dark;
    }
    this.$vuetify.theme.dark = this.getDark;
    // locale
    this.$vuetify.lang.current = this.$i18n.locale
  },
  computed: {
    ...mapGetters("user", [
      "getUsername",
      "getPassword",
      "getRemember",
      "getLocale",
      "getJwt",
      "getLoginLogo",
      "getLoginBackgroundImage",
      "getThemes"
    ]),
    ...mapGetters({
      getNotificationsCount: "notification/getNotificationsCount",
      getNotificationsStore: "notification/getNotifications",
      getDark: "app/getDark",
      getFirebaseToken: "messaging/getFirebaseToken"
    }),
    /**
     * src del logo
     */
    logoSource() {
      if (this.selectedEnterprise) {
        if (this.selectedEnterprise.logo) {
          return this.selectedEnterprise.logo;
        } else {
          return require("../../assets/logo.png");
        }
      } else if (this.getLoginLogo) {
        return "data:image/png;base64," + this.getLoginLogo;
      } else {
        return require("../../assets/logo.png");
      }
    },
    /**
     * src de la imagen de fondo
     */
    backgroundImageSource() {
      if (this.getLoginBackgroundImage) {
        return "data:image/png;base64," + this.getLoginBackgroundImage;
      } else {
        return require("../../assets/login-background.jpg");
      }
    }
  },
  methods: {
    ...mapState("app", {
      locale: state => state.locale
    }),
    ...mapActions("notification", {
      setNotifications: "setNotifications"
    }),
    ...mapMutations("app", ["setNavBarButtons"]),
    ...mapActions("user", {
      setUserId: "setUserId",
      setUsername: "setUsername",
      setPassword: "setPassword",
      setRemember: "setRemember",
      setEmail: "setEmail",
      // setJwt: "setJwt",
      setLogo: "setLogo",
      setLoginLogo: "setLoginLogo",
      setLoginBackgroundImage: "setLoginBackgroundImage",
      setThemes: "setThemes",
      setLocale: "setLocale",
      login: "login",
      setToken: "setToken",
      setPrivileges: "setPrivileges",
      setAdmin: "setAdmin",
      setLocales: "setLocales",
      setMenu: "setMenu",
      clearSessionData: "clearSessionData",
      setEnterpriseId: 'setEnterpriseId'
    }),
    ...mapActions({
      showSnackbar: "snackbar/showSnackbar",
      closeSnackbar: "snackbar/closeSnackbar"
    }),
    commitChanges() {
      const { username, /* password, */ remember } = this;
      this.setUsername(username);
      // this.setPassword(password);
      this.setRemember(remember);
    },
    setLanguage(lang) {
      this.selectedLanguage = lang;
      this.$root.$i18n.locale = lang.locale;
      localStorage.setItem("locale", lang.locale);
      this.setLocale(lang.locale);
    },
    forgotPassword() {
      this.$router.push("/reset-password").catch(err => {
        console.log(err);
      });
    },
    async login() {
      this.clearSessionData();
      try {
        let userSession = await loginService.login(
          this.username,
          this.password
        );
        this.setRemember(this.getRemember);
        this.setUserId(userSession.user.userId);
        this.setUsername(this.username);
        this.setEmail(userSession.user.email);
        this.setToken(userSession.sessionToken);

        if (userSession.updatePasswordType) {
          authApi
            .getResetPasswordToken({
              username: this.username,
              password: this.password
            })
            .then(tokenResponse => {
              // this.setJwt(tokenResponse.data.token);
              this.setToken(userSession.sessionToken);
              switch (userSession.updatePasswordType) {
                case ResetPasswordType.EXPIRATION:
                  this.$router.push(
                    "/reset-password/expiration/" + tokenResponse.data.token
                  );
                  break;
                case ResetPasswordType.NEXT_LOGIN:
                  this.$router.push(
                    "/reset-password/next-login/" + tokenResponse.data.token
                  );
                  break;
                default:
                  this.showSnackbar({
                    visible: true,
                    text: i18n.t("login.invalidPasswordResetType"),
                    timeout: 6000,
                    style: SnackbarStyle.ERROR
                  });
              }
            });
        } else {
          const userId = userSession.user._id;
          if(userId) {
            await messagingService.configureNotifications(userSession);
          }
        }
        if(userSession.user.haveGoogleAutenticatorSecret)
        {
          localStorage.setItem('haveGoogleAutenticatorSecret', true)
        }
        else{
          localStorage.removeItem('haveGoogleAutenticatorSecret')
        }
        if (userSession.enterprise && !userSession.user.haveGoogleAutenticatorSecret){
          this.selectedEnterprise = userSession.enterprise;
          await authService.setEnterprise(userSession.enterprise._id);
          userSession = await loginService.refreshToken(
            this.selectedEnterprise._id
          );
          await loginService.updateUserData(userSession, this.$i18n, true);
          await messagingService.configureNotifications(userSession);
        }else{
          await loginService.updateUserData(userSession, this.$i18n, true);
          await messagingService.configureNotifications(userSession);
          
        }
        this.$router.push("home");

      } catch (error) {
        console.log("Error in login action", error);
      }
    },
    async getEnterprises() {
      this.enterprises = (await enterpriseApi.getAllWithLogo()).data;
      this.enterprises.forEach(e => {
        e.logo = e.logo ? "data:image/png;base64," + e.logo : undefined;
      });
      this.selectedEnterprise = this.enterprises[0];
    },
    async submit() {
      if (this.showSelectEnterprise && this.selectedEnterprise) {
        let userSession = await loginService.refreshToken(
          this.selectedEnterprise.id
        );
        if (userSession.enterprise) {
          await loginService.updateUserData(userSession, this.$i18n, true);
          await messagingService.configureNotifications(userSession);
          this.$router.push("home");
        }
      } else {
        await this.login();
      }
    }
  },
  watch: {}
};
